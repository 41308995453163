import React, { useState, useEffect } from 'react';
import path from 'path';

const MaxThumbnailsWide = 5;

const Thumbnail = ({ src, onClick }) => (
  <img
    src={src}
    alt="thumbnail"
    style={{ width: 'auto', height: '100px', margin: '2px' }}
    onClick={onClick}
  />
);

export function MyImage({src, alt, maxWidth, maxHeight, setQueryParam}) {
  //const { src, alt, maxWidth, maxHeight } = props;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const newWidth = Math.min(img.width, maxWidth);
      const newHeight = Math.min(img.height, maxHeight);
      if (newWidth / newHeight > aspectRatio) {
        setHeight(newHeight);
        setWidth(newHeight * aspectRatio);
      } else {
        setWidth(newWidth);
        setHeight(newWidth / aspectRatio);
      }
      if (setQueryParam) {
        const imageName = `${path.parse(src).name.split('.').slice(0, -1).join('.')}${path.extname(src)}`;
        // console.log(`imageName ${imageName}`);
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set('imageName', imageName);
        window.history.pushState({}, '', newUrl);
      }
    };
  }, [src, maxWidth, maxHeight, setQueryParam]);

  return <img src={src} alt={alt} width={width} height={height} />;
}

function sortImagesByTaken(images, imageDescriptions) {
  const allImagesHaveTakenDate = imageDescriptions.every(image => image.Taken !== null);

  let sortedImages;
  if (allImagesHaveTakenDate) {
    sortedImages = imageDescriptions
      .sort((a, b) => new Date(a.Taken) - new Date(b.Taken))
      .map(image => images.find(img => img.includes(image.Name.split('.')[0])));
  } else {
    sortedImages = images;
  }

  return sortedImages;
}

function getStartIndex(images, imageName){
  if(imageName === ""){
    return 0;
  }

  return images.findIndex(image => {
    if (image !== undefined && image !== null) {
      try {
        const imageNameWithoutExtension = path.parse(image).name.split('.').slice(0, -1).join('.');
        return `${imageNameWithoutExtension}${path.extname(image)}` === imageName;
      } catch (error) {
        console.error('An error occurred:', error.message);
      }
      return false;
    }
    return false;
  });
}

function Gallery({ req, title, description, imageDescriptions }) {
  const searchParams = new URLSearchParams(window.location.search);
  const imageName = searchParams.get('imageName') || '';
  const images = sortImagesByTaken(req.keys().map(req), imageDescriptions);
  var startImageIndex = getStartIndex(images, imageName);
  const [selectedImage, setSelectedImage] = useState(images[startImageIndex]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(startImageIndex);

  const handleThumbnailClick = (image, index) => {
    startImageIndex = index + 1;   
    setSelectedImage(image);
  };

  const thumbnailsWide = Math.min(MaxThumbnailsWide, images.length);
  const startIndex = Math.max(0, Math.min(selectedImageIndex, images.length - thumbnailsWide));
  const endIndex = Math.min(startIndex + thumbnailsWide - 1, images.length - 1);
  const visibleImages = images.slice(startIndex, endIndex + 1);

  const getImageDescription = (imageName) => {
    if (!imageDescriptions) {
      return imageName.replace(/_/g, ' ');
    }
    
    const imageObject = imageDescriptions.find((image) => image.Name.split('.')[0] === imageName);
    
    if (imageObject.Description !== '') {
      return imageObject.Description;
    } else {
      return imageName.replace(/_/g, ' ');
    }
  };

  const handlePreviousImageClick = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
      setSelectedImage(images[selectedImageIndex - 1]);
    }
  };
  
  const handleNextImageClick = () => {
    if (selectedImageIndex < images.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
      setSelectedImage(images[selectedImageIndex + 1]);
    }
  };

  const handlePreviousClick = (index) => {
    setSelectedImageIndex(Math.max(0, selectedImageIndex - index));
  };

  const handleNextClick = (index) => {
    setSelectedImageIndex(Math.min(images.length - index, selectedImageIndex + index));
  };

  const handleResetStartClick = () => {
    setSelectedImageIndex(0);
  };

  const handleResetEndClick = () => {
    setSelectedImageIndex(images.length - thumbnailsWide);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <p><font size="6">{title}</font></p>
      {description && description !== "" && (
        <>{description}</>
        )}
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {selectedImageIndex > 0 && (
            <button onClick={handlePreviousImageClick}>Prev Image</button>
        )}
        <MyImage src={selectedImage} alt="selected" maxWidth={1000} maxHeight={1000} setQueryParam={true} />
        {selectedImageIndex < images.length - 1 && (
          <button onClick={handleNextImageClick}>Next Image</button>
        )}
      </div>
      <div style={{ marginTop: '10px' }}>
        {`${getImageDescription(path.parse(selectedImage).name.split('.').slice(0, -1).join('.'))}`}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {images.length > 3 * thumbnailsWide && (
            <>
                <button onClick={handleResetStartClick} disabled={selectedImageIndex === 0}>First Page</button>
            </>
        )}
        {images.length > thumbnailsWide && (
            <>
                <button onClick={() => handlePreviousClick(thumbnailsWide)} disabled={selectedImageIndex === 0}>Prev Page</button>
                {/* <button onClick={() => handlePreviousClick(1)} disabled={selectedImageIndex === 0}>Prev Image</button> */}
            </>
        )}
        {visibleImages.map((image, index) => (
          <Thumbnail
            key={startIndex + index}
            src={image}
            onClick={() => handleThumbnailClick(image, startIndex + index)}
          />
        ))}
        {images.length > thumbnailsWide && (
            <>
                {/* <button onClick={() => handleNextClick(1)} disabled={selectedImageIndex >= images.length - thumbnailsWide}>Next Image</button> */}
                <button onClick={() => handleNextClick(thumbnailsWide)} disabled={selectedImageIndex >= images.length - thumbnailsWide}>Next Page</button>
            </>
        )}        
        {images.length > 3 * thumbnailsWide && (
            <>
                <button onClick={handleResetEndClick} disabled={selectedImageIndex >= images.length - thumbnailsWide}>Last Page</button>
            </>
        )}
      </div>
      <div style={{ marginTop: '10px' }}>
        {`Images ${startIndex + 1} thru ${endIndex + 1} out of ${images.length}`}
      </div>
    </div>
  );
};

export default Gallery;
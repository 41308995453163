import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function LinkList(props) {
  const location = useLocation();
  function handleClick() {
    window.scrollTo(0, 0);
  }
  return (
    <td valign="top" style={{ width: '225px', height: '300px' }}>
      <ul className="link-list">
        {props.links.map(link => (
          <li key={link.url}>
            <NavLink to={link.url} onClick={handleClick} className={location.pathname.startsWith(link.url) ? "active" : ""}>{link.text}</NavLink>
          </li>
        ))}
      </ul>
    </td>
  );
}

export default LinkList;
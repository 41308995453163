import React, { useEffect } from 'react';
import momentTimezone from 'moment-timezone';
import LinkList from '../LinkList';
import { getAgeAndBirthMessage } from '../Helpers';
import NavBar from '../NavBar';
import Gallery from '../Gallery';
import { addRoute } from '../../GlobalRoutes';
import TiffanysFirstYear from './Tiffany/Tiffany\'s_first_year.gif';
import { myChildrenlinks } from './index';

const links = [
  { text: 'Tiffany Home', url: '/my-children-tiffany' },
];

const tiffanyYearMappings = {
  'Artwork': {
    images: require.context('./Tiffany/Artwork', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/Artwork/descriptions.json')
  },
  '16thYear': {
    images: require.context('./Tiffany/16thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/16thYear/descriptions.json')
  },
  '15thYear': {
    images: require.context('./Tiffany/15thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/15thYear/descriptions.json')
  },
  '14thYear': {
    images: require.context('./Tiffany/14thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/14thYear/descriptions.json')
  },
  '13thYear': {
    images: require.context('./Tiffany/13thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/13thYear/descriptions.json')
  },
  '12thYear': {
    images: require.context('./Tiffany/12thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/12thYear/descriptions.json')
  },
  '11thYear': {
    images: require.context('./Tiffany/11thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/11thYear/descriptions.json')
  },
  '10thYear': {
    images: require.context('./Tiffany/10thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/10thYear/descriptions.json')
  },
  '9thYear': {
    images: require.context('./Tiffany/9thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/9thYear/descriptions.json')
  },
  '8thYear': {
    images: require.context('./Tiffany/8thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/8thYear/descriptions.json')
  },
  '7thYear': {
    images: require.context('./Tiffany/7thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/7thYear/descriptions.json')
  },
  '6thYear': {
    images: require.context('./Tiffany/6thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/6thYear/descriptions.json')
  },
  '5thYear': {
    images: require.context('./Tiffany/5thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/5thYear/descriptions.json')
  },
  '4thYear': {
    images: require.context('./Tiffany/4thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/4thYear/descriptions.json')
  },
  '3rdYear': {
    images: require.context('./Tiffany/3rdYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/3rdYear/descriptions.json')
  },
  '2ndYear': {
    images: require.context('./Tiffany/2ndYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/2ndYear/descriptions.json')
  },
  '1stYear': {
    images: require.context('./Tiffany/1stYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/1stYear/descriptions.json')
  },
  'Ultrasounds': {
    images: require.context('./Tiffany/Ultrasounds', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/Ultrasounds/descriptions.json')
  },
  'Room': {
    images: require.context('./Tiffany/Room', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/Room/descriptions.json')
  },
  'Encampment': {
    images: require.context('./Tiffany/Encampment', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./Tiffany/Encampment/descriptions.json')
  },
};

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
      <tbody>
        <tr>
          <LinkList links={links} />
          <td valign="top">
            <div className="content"><NavBar links={myChildrenlinks} /></div>
            <hr />
            <div className="content">{props.children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function withYear(year) {
  const Component = function TiffanyYear() {
    useEffect(() => {
      document.title = `TomsSpot.com - My Children - Tiffany - ${year}`;
    }, []);

    const { images, descriptions } = tiffanyYearMappings[year];

    return (
      <Content>
        <Gallery 
          req={images} 
          imageDescriptions={descriptions} 
        />
      </Content>
    );
  };

  addRoute(`/my-children-tiffany-${year.toLowerCase()}`, <Component />);

  links.push({ text: `${year}`, url: `/my-children-tiffany-${year.toLowerCase()}` });

  return Component;
}

Object.entries(tiffanyYearMappings).forEach(([year]) => {
  withYear(year);
});

function Tiffany() {
  useEffect(() => {
    document.title = 'TomsSpot.com - My Children - Tiffany';
  });
  const name = "Tiffany Alexis Lake";
  const masculine = false;
  const dateOfBirth = momentTimezone.tz('2005-04-06T07:31:00', 'America/Los_Angeles');
  const weight = '7 lbs 11 oz';
  const length = '19 1/4 inches';
  
  return (
    <Content>
      <p>{getAgeAndBirthMessage(name, masculine, dateOfBirth, weight, length)}</p>
      <p><img src={TiffanysFirstYear} alt="Tiffany' First Year" /></p>
      <p>Click on one of the links to the left for more pictures.</p>
    </Content>
  );
}

export default Tiffany;

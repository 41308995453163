import React, { useState, useEffect  } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { addRoute } from '../../../../GlobalRoutes';

const defaultGift = {
  gift: "",
  color: "",
  size: "",
  link: "",
};

const numberGifts = 10;

export const XmasGiftForm = () => {
  const [giftCount] = useState(numberGifts);
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      gifts: Array.from({ length: giftCount }, () => (defaultGift)),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "gifts",
  });

  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Xmas - Send Christmas Wish List to Santa';
  }, [giftCount,control,fields]);

  const onSubmit = (data) => {
    console.log(`data ${JSON.stringify(data)}`);
    
    const filteredGifts = data.gifts.filter(gift => {
      return Object.values(gift).some(property => property !== '');
    });
    
    console.log(`filteredGifts ${JSON.stringify(filteredGifts)}`);
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <form onSubmit={handleSubmit(onSubmit,onError)}>
      {fields.map((field, index) => (
        <div key={field.id} align="center">
          <label htmlFor={`gifts[${index}].gift`}>Gift:</label>
          <input
            type="text"
            name={`gifts[${index}].gift`}
            {...register(`gifts[${index}].gift`)}
          />

          <label htmlFor={`gifts[${index}].color`}>Color:</label>
          <input
            type="text"
            name={`gifts[${index}].color`}
            {...register(`gifts[${index}].color`)}
          />

          <label htmlFor={`gifts[${index}].size`}>Size:</label>
          <input
            type="text"
            name={`gifts[${index}].size`}
            {...register(`gifts[${index}].size`)}
          />

          <label htmlFor={`gifts[${index}].link`}>Link:</label>
          <input
            type="text"
            name={`gifts[${index}].link`}
            {...register(`gifts[${index}].link`)}
          />
        </div>
      ))}
      <div align="center">
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};


addRoute('/about-me-family-xmas-email-santa', <XmasGiftForm />);

export default XmasGiftForm;

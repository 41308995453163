import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { addRoute, GlobalRoutes } from './GlobalRoutes';
import NavBar from './components/NavBar';

// import HitCounter from './components/HitCounter';
import MyChildren from './components/MyChildren/index';
import Links from './components/Links/index';
import WhatsNew from './components/WhatsNew/index';
import Pets from './components/Pets/Pets';
import { AboutMe } from './components/AboutMe/index';
import XmasGiftForm from './components/AboutMe/Family/Xmas/index';

import Tom from './Tom.jpg';

const mostRecent = '/my-children-2019'
const currentWhatsNewPage = '/whats-new-christmas-2010';
const currentPetsPage = '/pets-rosalie';

const mainLinks = [
  { text: 'Home', url: '/' },
  { text: 'About Me', url: '/about-me' },
  { text: 'My Children', url: '/my-children' },
  { text: 'Pets', url: currentPetsPage },
  { text: 'What\'s New', url: currentWhatsNewPage },
  { text: 'Links', url: '/links' }
];

function Header() {
  return (
    <div className="header">
      <p><font size="7">Tom's Spot.com</font></p>
      <p><font size="4">This is Tom's little spot on the internet</font></p>
    </div>
  );
}

function Home() {
  useEffect(() => {
    document.title = 'TomsSpot.com - Home';
  });

  return (
    <div>
      <p>Welcome to Tom's Spot.com</p>
      <p><img width="300" height="300" src={Tom} alt="Me" /></p>
      <p>Please click on the <a href="/about-me" title="About Me">About Me</a>, <a href="/my-children" title="My Children">My Children</a>, <a href={currentPetsPage} title="Pets">Pets</a>, <a href={currentWhatsNewPage} title="What's New">What's New</a> or <a href="/links" title="Links">Links</a> links to the top of the page.</p>
      <p>The most recently added pictures are of My Children from 2019 and can be found under <a href={mostRecent} title="My Children 2019">My Children 2019</a>.</p>
      <p>Come back again soon.</p>
    </div>
  );
}

function Footer() {
  return (
    <div className="footer">
      {/* <HitCounter /> */}
      <p>© 2024 by Tom's Spot. All rights reserved.</p>
    </div>
  );
}

// const express = require('express');
// const fs = require('fs-extra');
// const app = express();

// app.use(express.json());

// app.post('/api/endpoint', (req, res) => {
//   fs.writeFile('./components/hits.json', req.body, (err) => {
//     if (err) {
//       console.error(err);
//       res.status(500).send('Error writing to file');
//     } else {
//       res.send('Data written to file');
//     }
//   });
// });

function App(props) {
  return (
    <Router>
      <table className="app-container">
        <tbody>
          <tr>
            <td>
              <Header />
              <NavBar links={mainLinks} />
              <hr />
              <GlobalRoutes />
              <hr />
              <Footer />
            </td>
          </tr>
        </tbody>
      </table>
    </Router>
  );
}

addRoute('/', <Home />);
addRoute('/about-me', <AboutMe />);
addRoute('/my-children', <MyChildren />);
addRoute(currentPetsPage, <Pets />);
addRoute(currentWhatsNewPage, <WhatsNew />);
addRoute('/links', <Links />);
addRoute('/about-me-family-xmas-email-santa', <XmasGiftForm />);

export default App;
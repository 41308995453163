import React, { useEffect } from 'react';
import { addRoute } from '../../../GlobalRoutes';
import LinkList from '../../LinkList';
import Gallery from '../../Gallery';
import NavBar from '../../NavBar';
import { aboutMeLinks } from '../index';

import AboutMeFamily from '../Family/index';

export const traveledLinks = [
  { text: 'Travel Home', url: '/about-me-traveled' },
  { text: '1976', url: '/about-me-traveled-1976' },
  { text: 'Hawaii to Scotland Summer 1977', url: '/about-me-traveled-hawaii-to-scotland-summer-1977' },
  { text: 'Bangkok August 1977', url: '/about-me-traveled-bangkok-august-1977' },
  { text: 'Singapore_Penang_Hong_Kong_Aug_1977', url: '/about-me-traveled-singapore-penang-hong-kong-aug-1977' },
  { text: '1978', url: '/about-me-traveled-1978' },
  { text: '1979', url: '/about-me-traveled-1979' },
  { text: 'Africa', url: '/about-me-traveled-africa' },
  { text: 'Panama Canal', url: '/about-me-traveled-panama-canal' },
  { text: 'China', url: '/about-me-traveled-China' },
];

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
      <tbody>
        <tr>
          <LinkList links={aboutMeLinks} />
          <td valign="top">
          {(
            <>
              <div className="content"><NavBar links={traveledLinks} /></div>  
              <hr />
            </>
          )}                 
          <div className="content">{props.children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function AboutMeTraveledChina(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled - China';
  });
  return (
    <Content>
      <Gallery req={require.context('./China', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./China/descriptions.json')} />
    </Content>
  );
}

function AboutMeTraveled1976(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled - 1976';
  });
  return (
    <Content>
      <Gallery req={require.context('./1976', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./1976/descriptions.json')} />
    </Content>
  );
}

function AboutMeTraveled1978(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled - 1978';
  });
  return (
    <Content>
      <Gallery req={require.context('./1978', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./1978/descriptions.json')} />
    </Content>
  );
}

function AboutMeTraveled1979(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled - 1979';
  });
  return (
    <Content>
      <Gallery req={require.context('./1979', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./1979/descriptions.json')} />
    </Content>
  );
}

function AboutMeTraveledAfrica(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled - Africa';
  });
  return (
    <Content>
      <Gallery req={require.context('./Africa', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Africa/descriptions.json')} />
    </Content>
  );
}

function AboutMeTraveledBangkokAugust1977(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled - Bangkok August 1977';
  });
  return (
    <Content>
      <Gallery req={require.context('./Bangkok_August_1977', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Bangkok_August_1977/descriptions.json')} />
    </Content>
  );
}

function AboutMeTraveledHawaiiToScotlandSummer1977(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled - Hawaii to Scotland Summer 1977';
  });
  return (
    <Content>
      <Gallery req={require.context('./Hawaii_to_Scotland_Summer_1977', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Hawaii_to_Scotland_Summer_1977/descriptions.json')} />
    </Content>
  );
}

function AboutMeTraveledPanamaCanal(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled - Panama Canal';
  });
  return (
    <Content>
      <Gallery req={require.context('./Panama_Canal', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Panama_Canal/descriptions.json')} />
    </Content>
  );
}

function AboutMeTraveledSingaporePenangHongKongAug1977(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled - Singapore Penang Hong Kong Aug 1977';
  });
  return (
    <Content>
      <Gallery req={require.context('./Singapore_Penang_Hong_Kong_Aug_1977', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Singapore_Penang_Hong_Kong_Aug_1977/descriptions.json')} />
    </Content>
  );
}

export const AboutMeTraveled=() => {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Traveled';
  });

  return (
    <Content>
      <p className="left-justify">I am just getting started on this page. Here are most of the countries that I have been to. If you know of any good links for any of these countries please sent them to me at <a title="Email Tom" href="mailto:tom@tomsspot.com">Tom@TomsSpot.com</a></p>
      <p className="left-justify">In no particular order yet:<br/><br/>Scotland, England, Wales, <a href="/about-me-traveled-china" title="Pictures of China Vacation">China</a>, <a href="/about-me-traveled-bangkok-august-1977" title="Pictures of Bangkok August 1977 Vacation">Thailand</a>, <a href="/about-me-traveled-singapore-penang-hong-kong-aug-1977" title="Pictures of Singapore, Penang and Hong Kong August 1977 Vacation">Hong Kong</a>, <a href="/about-me-traveled-singapore-penang-hong-kong-aug-1977" title="Pictures of Singapore, Penang and Hong Kong August 1977 Vacation">Penang</a>, <a href="/about-me-traveled-singapore-penang-hong-kong-aug-1977" title="Pictures of Singapore, Penang and Hong Kong August 1977 Vacation">Singapore</a>, Malaysia, India, Switzerland, Germany, Austria, Holland, France, Belgium, <a href="/about-me-traveled-africa" title="Pictures of Africa Vacation">Kenya</a>, <a href="/about-me-traveled-africa" title="Pictures of Africa Vacation">Tanzania</a>, Saudi Arabia, Bahrain, Iraq, Canada, Mexico, <a href="/about-me-traveled-panama-canal" title="Pictures of Panama Canal Cruise">Colombia</a>, <a href="/about-me-traveled-panama-canal" title="Pictures of Panama Canal Cruise">Aruba</a>, <a href="/about-me-traveled-panama-canal" title="Pictures of Panama Canal Cruise">Panama</a>, <a href="/about-me-traveled-panama-canal" title="Pictures of Panama Canal Cruise">San Blas Islands</a>, <a href="/about-me-traveled-panama-canal" title="Pictures of Panama Canal Cruise">Virgin Islands</a>, &amp; <a href="/about-me-traveled-panama-canal" title="Pictures of Panama Canal Cruise">Puerto Rico</a>, Greece.</p>
      <p className="left-justify">The scanning project has started.  However due to the large number of slides there are it is going to take some time to complete the project. Keep checking back from time to time to see the latest slides that have been added.</p>
    </Content>
  );
}


addRoute('/about-me-family', <AboutMeFamily />);
addRoute('/about-me-traveled', <AboutMeTraveled />);
addRoute('/about-me-traveled-1976', <AboutMeTraveled1976 />);
addRoute('/about-me-traveled-hawaii-to-scotland-summer-1977', <AboutMeTraveledHawaiiToScotlandSummer1977 />);
addRoute('/about-me-traveled-bangkok-august-1977', <AboutMeTraveledBangkokAugust1977 />);
addRoute('/about-me-traveled-singapore-penang-hong-kong-aug-1977', <AboutMeTraveledSingaporePenangHongKongAug1977 />);
addRoute('/about-me-traveled-1978', <AboutMeTraveled1978 />);
addRoute('/about-me-traveled-1979', <AboutMeTraveled1979 />);
addRoute('/about-me-traveled-africa', <AboutMeTraveledAfrica />);
addRoute('/about-me-traveled-panama-canal', <AboutMeTraveledPanamaCanal />);
addRoute('/about-me-traveled-china', <AboutMeTraveledChina />);

export default AboutMeTraveled;
import React, { useEffect } from 'react';
import LinkList from '../LinkList';
import NavBar from '../NavBar';
import Gallery from '../Gallery';
import Christmas2008 from './Thomas_Jr_and_Tiffany_with_Santa_-_Christmas_2008.jpg';
import { addRoute } from '../../GlobalRoutes';
import ThomasJr from './Thomas_Jr'
import Tiffany from './Tiffany'

const links = [
  { text: 'My Children Home', url: '/my-children' },
];

export const myChildrenlinks = [
  { text: 'My Children Home', url: '/my-children' },
  { text: 'Thomas Jr', url: '/my-children-thomasjr' },
  { text: 'Tiffany', url: '/my-children-tiffany' },
];

const yearMappings = {
  2019: {
    images: require.context('./2019', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2019/descriptions.json')
  },
  2018: {
    images: require.context('./2018', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2018/descriptions.json')
  },
  2017: {
    images: require.context('./2017', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2017/descriptions.json')
  },
  2016: {
    images: require.context('./2016', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2016/descriptions.json')
  },
  2015: {
    images: require.context('./2015', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2015/descriptions.json')
  },
  2014: {
    images: require.context('./2014', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2014/descriptions.json')
  },
  2013: {
    images: require.context('./2013', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2013/descriptions.json')
  },
  2012: {
    images: require.context('./2012', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2012/descriptions.json')
  },
  2011: {
    images: require.context('./2011', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2011/descriptions.json')
  },
  2010: {
    images: require.context('./2010', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2010/descriptions.json')
  },
  2009: {
    images: require.context('./2009', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2009/descriptions.json')
  },
  2008: {
    images: require.context('./2008', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2008/descriptions.json')
  },
  2007: {
    images: require.context('./2007', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2007/descriptions.json')
  },
  2006: {
    images: require.context('./2006', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2006/descriptions.json')
  },
  2005: {
    images: require.context('./2005', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./2005/descriptions.json')
  },
};

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
      <tbody>
        <tr>
          <LinkList links={links} />
          <td valign="top">
            <div className="content"><NavBar links={myChildrenlinks} /></div>
            <hr />
            <div className="content">{props.children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function withYear(year) {
  const Component = function MyChildren() {
    useEffect(() => {
      document.title = `TomsSpot.com - My Children - ${year}`;
    }, []);

    const { images, descriptions } = yearMappings[year];

    return (
      <Content>
        <Gallery 
          req={images} 
          imageDescriptions={descriptions} 
        />
      </Content>
    );
  };

  addRoute(`/my-children-${year}`, <Component />);

  links.push({ text: `${year}`, url: `/my-children-${year}` });

  return Component;
}

Object.keys(yearMappings).sort((a, b) => b - a).forEach(year => {
  withYear(year);
});

function MyChildren() {
  useEffect(() => {
    document.title = 'TomsSpot.com - My Children';
  });

  return (
    <Content>
      <p>Welcome to Thomas Jr and Tiffany's home page.</p>
	    <p>Come here often to see new pictures of them.</p>
      <p><img src={Christmas2008} alt="Thomas Jr and Tiffany with Santa - Christmas 2008" /></p>
      <p>Click on one of the links to the left or the Thomas Jr or Tiffany links above.</p>
    </Content>
  );
}

addRoute('/my-children-thomasjr', <ThomasJr />);
addRoute('/my-children-tiffany', <Tiffany />);

export default MyChildren;

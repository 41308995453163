import React, { useEffect } from 'react';

function Links() {
  useEffect(() => {
    document.title = 'TomsSpot.com - Links';
  });

  return (
    <div>
		<p>
				<a href="http://blogs.msdn.com/tomlake" target="_blank" rel="noreferrer">
					My blog for work</a>
		</p>


		<p>
				<a href="https://www.lasikplus.com/location/seattle-lasik-center" target="_blank" rel="noreferrer">
					Restore Vision Centers
				</a> - This is where I had Laser Eye Surgery on 11/19/2001.  
				I went from 20/800 to 20/15.  If you have every thought about 
				getting it done I would recommend doing it as soon as possible.
		</p>
		
		<p>
				<a href="https://www.mlb.com/mariners" target="_blank" rel="noreferrer">
					Seattle Mariners</a> - Go Mariners
		</p>


		<p>
				<a href="http://www.seahawks.com" target="_blank" rel="noreferrer">
					Seattle Seahawks</a>
		</p>


		<p>
				<a href="http://www.dslreports.com/speedtest" target="_blank" rel="noreferrer">
					Test your internet speed </a>
		</p>


		<p>
				<a href="https://www.bing.com/" target="_blank" rel="noreferrer">
					Bing.com</a> - search engine - friends don't let friends Google.
		</p>


		<p>
				<a href="https://www.aramco-brats.com/" target="_blank" rel="noreferrer">
					ARAMCO Brats</a>
		</p>

		<p>
				<a href="https://www.seattletimes.com/" target="_blank" rel="noreferrer">
					Seattle Times</a>
		</p>

		<p>
				<a href="http://www.msnbc.com/" target="_blank" rel="noreferrer">
					MSNBC</a>
		</p>

		<p>
				<a href="http://www.cnn.com/" target="_blank" rel="noreferrer">
					CNN</a>
		</p>

		<p>
				<a href="http://www.netflix.com/Default/" target="_blank" rel="noreferrer">
					Netflix.com</a> - movie rentals
		</p>

		<p>
				<a href="http://www.godaddy.com" target="_blank" rel="noreferrer">
					GoDaddy.com</a> - Where I have my website hosted.
		</p>

		<p>
				<a href="http://www.GasPriceWatch.com/" target="_blank" rel="noreferrer">
					Gas Price Watch</a>
		</p>

		<p>
				<a href="https://www.magiceye.com/stwkdisp.htm" target="_blank" rel="noreferrer">
					Magic Eye</a> - Image of the Week
		</p>

		<p>
				<a href="https://uroulette.com/visit/tvnut" target="_blank" rel="noreferrer">
					Random URLs</a>
		</p>
    </div>
  );
}

export default Links;

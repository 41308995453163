import React from 'react';
import { Route, Routes } from 'react-router-dom';

const routes = [];

export const addRoute = (path, component) => {
  routes.push({ path, component });
};

export const GlobalRoutes = () => (
  <>
    <Routes>
      {routes.map(({ path, component }) => (
        <Route key={path} path={path} element={component} />
      ))}
    </Routes>
  </>
);

import React, { useEffect } from 'react';
import Lake_Family_Coat_of_Arms from './Lake_Family_Coat_of_Arms.jpg';
import { addRoute } from '../../../GlobalRoutes';
import LinkList from '../../LinkList';
import NavBar from '../../NavBar';
import Gallery from '../../Gallery';
import { aboutMeLinks } from '../index';

const subLinks = [
  { text: 'Family Home', url: '/about-me-family' },
  { text: 'Dad', url: '/about-me-family-dad' },
  { text: 'Mom', url: '/about-me-family-mom' },
  { text: 'Tom', url: '/about-me-family-tom' },
  { text: 'Janet', url: '/about-me-family-janet' },
  { text: 'Pam', url: '/about-me-family-pam' },
  { text: 'Chris', url: '/about-me-family-chris' },
  { text: 'Amanda & Marcus', url: '/about-me-family-amanda-and-marcus' },
  { text: 'Assorted', url: '/about-me-family-assorted' },
  { text: 'Rumwell\'s', url: '/about-me-family-rumwells' },
  { text: 'Family 1961 to 1978', url: '/about-me-family-family-1961-to-1978' },
  { text: 'ARAMCO buildings 1978 to 1979', url: '/about-me-family-aramco-buildings-1978-to-1979' }
];

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
      <tbody>
        <tr>
          <LinkList links={aboutMeLinks} />
          <td valign="top">
            <div className="content">
              <NavBar links={subLinks} />
            </div>  
            <hr />          
            <div className="content">
              {props.children}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export const AboutMeFamily=(props) => {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family';
  });

  return (
    <Content>
      <p><font size="6">Family Home</font></p>
      <p>This is where I will put family information, pictures, and things that are upcoming. Go to <a href="http://tomlake.tribalpages.com" target="_blank" rel="noreferrer">http://tomlake.tribalpages.com</a> for a family tree.</p>
      <p><img src={Lake_Family_Coat_of_Arms} alt="Lake Family Coat of Arms" /></p>
    </Content>
  );
}

function Dad(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Dad';
  });
  return (
    <Content>
      <Gallery req={require.context('./Dad', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Dad/descriptions.json')} />
    </Content>
  );
}

function Mom(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Mom';
  });
  return (
    <Content>
      <Gallery req={require.context('./Mom', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Mom/descriptions.json')} />
    </Content>
  );
}

function Tom(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Tom';
  });
  return (
    <Content>
      <Gallery req={require.context('./Tom', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Tom/descriptions.json')} />
    </Content>
  );
}

function Janet(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Janet';
  });
  return (
    <Content>
      <Gallery req={require.context('./Janet', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Janet/descriptions.json')} />
    </Content>
  );
}

function Pam(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Pam';
  });
  return (
    <Content>
      <Gallery req={require.context('./Pam', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Pam/descriptions.json')} />
    </Content>
  );
}

function Chris(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Chris';
  });
  return (
    <Content>
      <Gallery req={require.context('./Chris', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Chris/descriptions.json')} />
    </Content>
  );
}

function AssortedFamily(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Assorted Family';
  });
  return (
    <Content>
      <Gallery req={require.context('./Assorted_Family', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Assorted_Family/descriptions.json')} />
    </Content>
  );
}

function AmandaAndMarcus(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Amanda and Marcus';
  });
  return (
    <Content>
      <Gallery req={require.context('./Amanda_and_Marcus', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Amanda_and_Marcus/descriptions.json')} />
    </Content>
  );
}

function Rumwells(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Rumwells';
  });
  return (
    <Content>
      <Gallery req={require.context('./Rumwell\'s', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Rumwell\'s/descriptions.json')} />
    </Content>
  );
}

function Family1961To1978(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Family 1961 to 1978';
  });
  return (
    <Content>
      <Gallery req={require.context('./Family_1961_to_1978', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Family_1961_to_1978/descriptions.json')} />
    </Content>
  );
}

function FamilyAramcoBuildings1978To1979(props) {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - ARAMCO buildings 1978 to 1979';
  });
  return (
    <Content>
      <Gallery req={require.context('./ARAMCO_buildings_1978_to_1979', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./ARAMCO_buildings_1978_to_1979/descriptions.json')} />
    </Content>
  );
}

addRoute('/about-me-family-dad', <Dad />);
addRoute('/about-me-family-mom', <Mom />);
addRoute('/about-me-family-tom', <Tom />);
addRoute('/about-me-family-janet', <Janet />);
addRoute('/about-me-family-pam', <Pam />);
addRoute('/about-me-family-chris', <Chris />);
addRoute('/about-me-family-rumwells', <Rumwells />);
addRoute('/about-me-family-amanda-and-marcus', <AmandaAndMarcus />);
addRoute('/about-me-family-assorted', <AssortedFamily />);
addRoute('/about-me-family-family-1961-to-1978', <Family1961To1978 />);
addRoute('/about-me-family-aramco-buildings-1978-to-1979', <FamilyAramcoBuildings1978To1979 />);

export default AboutMeFamily;
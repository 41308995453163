import React, { useEffect } from 'react';
import momentTimezone from 'moment-timezone';
import { addRoute } from '../../GlobalRoutes';
import LinkList from '../LinkList';
import Gallery from '../Gallery';
import { getAgeMessage } from '../Helpers';

export const petsLinks = [
  { text: 'Juliette', url: '/pets-juliette' },
  { text: 'Rosalie', url: '/pets-rosalie' },
  { text: 'Zeus', url: '/pets-zeus' },
  { text: 'Mousse', url: '/pets-mousse' },
  { text: 'Optimus', url: '/pets-optimus' },
];

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
      <tbody>
        <tr>
          <LinkList links={petsLinks} />
          <td valign="top">
            <div className="content">{props.children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function PetsRosalie() {
  useEffect(() => {
    document.title = 'TomsSpot.com - Pets - Rosalie';
  });
  const name = "Rosalie";
  const masculine = false;
  const dateOfBirth = momentTimezone.tz('2020-11-09', 'America/Chicago');
  const description = (
    <>
      <p>We got Rosalie on January 4, 2021 from <a href="https://www.puppycreek.com/" target="_blank" rel="noreferrer">PuppyCreek Kennels</a> in Witter, AR.</p>
      <p>{getAgeMessage(name, masculine, dateOfBirth)}</p>
    </>
  );  
  return (
    <Content>
    <Gallery req={require.context('./Rosalie', false, /\.(jpe?g|png|gif|svg)$/i)} title={name} description={description} imageDescriptions={require('./Rosalie/descriptions.json')} />
    </Content>
  );
}

function PetsJuliette() {
  useEffect(() => {
    document.title = 'TomsSpot.com - Pets - Juliette';
  });
  const name = "Juliette";
  const masculine = false;
  const dateOfBirth = momentTimezone.tz('2023-12-11', 'America/Chicago');
  const description = (
    <>
      <p>We got Juliette on February 12, 2024 from <a href="https://www.puppycreek.com/" target="_blank" rel="noreferrer">PuppyCreek Kennels</a> in Witter, AR.</p>
      <p>{getAgeMessage(name, masculine, dateOfBirth)}</p>
    </>
  );
  return (
    <Content>
      <Gallery req={require.context('./Juliette', false, /\.(jpe?g|png|gif|svg)$/i)} title={name} description={description} imageDescriptions={require('./Juliette/descriptions.json')} />
    </Content>
  );
}

function PetsZeus() {
  useEffect(() => {
    document.title = 'TomsSpot.com - Pets - Zeus';
  });
  const name = "Zeus";
  const masculine = true;
  const dateOfBirth = momentTimezone.tz('2017-07-14', 'America/Los_Angeles');
  const dateOfDeath = momentTimezone.tz('2024-01-12', 'America/Los_Angeles');

  const description = (
    <>
      <p>We got Zeus on September 9, 2017 from <a href="https://www.windyriverranchrottweilers.com/" target="_blank" rel="noreferrer">Windy River Ranch Rottweilers</a> in Lyle, WA.</p>
      <p>{getAgeMessage(name, masculine, dateOfBirth, dateOfDeath)}</p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Zeus', false, /\.(jpe?g|png|gif|svg)$/i)}  title={name} description={description} imageDescriptions={require('./Zeus/descriptions.json')} />
    </Content>
  );
}

function PetsOptimus() {
  useEffect(() => {
    document.title = 'TomsSpot.com - Pets - Optimus';
  });
  const name = "Optimus";
  const masculine = true;
  const dateOfBirth = momentTimezone.tz('2009-10-28', 'America/Los_Angeles');
  const dateOfDeath = momentTimezone.tz('2020-10-16', 'America/Los_Angeles');

  const description = (
    <>
      <p>We got Optimus on December 15, 2009.</p>
      <p>{getAgeMessage(name, masculine, dateOfBirth, dateOfDeath)}</p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Optimus', false, /\.(jpe?g|png|gif|svg)$/i)}  title={name} description={description} imageDescriptions={require('./Optimus/descriptions.json')} />
    </Content>
  );
}

function PetsMousse() {
  useEffect(() => {
    document.title = 'TomsSpot.com - Pets - Mousse';
  });
  const name = "Mousse";
  const masculine = true;
  const dateOfBirth = momentTimezone.tz('2012-12-29', 'America/Los_Angeles');
  const dateOfDeath = momentTimezone.tz('2016-06-21', 'America/Los_Angeles');

  const description = (
    <>
      <p>We got Mousse on February 23, 2013.</p>
      <p>{getAgeMessage(name, masculine, dateOfBirth, dateOfDeath)}</p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Mousse', false, /\.(jpe?g|png|gif|svg)$/i)}  title={name} description={description} imageDescriptions={require('./Mousse/descriptions.json')} />
    </Content>
  );
}

addRoute('/pets-juliette', <PetsJuliette />);
addRoute('/pets-rosalie', <PetsRosalie />);
addRoute('/pets-zeus', <PetsZeus />);
addRoute('/pets-optimus', <PetsOptimus />);
addRoute('/pets-mousse', <PetsMousse />);

export default PetsRosalie;